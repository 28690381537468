<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Produkty - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <a-col class="table-operations">
        <language-tab></language-tab>
        <a-form :form="form" @submit="handleSubmit">
          <a-tabs @change="key => onTabChange(key, 'tabActiveKey')" :activeKey="tabActiveKey">
            <a-tab-pane key="main">
              <a-badge dot slot="tab">
                <ion-icon class="anticon hydrated" name="information-circle-outline"></ion-icon>
                Hlavní údaje
              </a-badge>
              <h4 class="text-black mt-4 mb-3"><strong>Nastavitelné podle jazyka</strong></h4>

              <a-form-item
                label="Název"
                :validate-status="error('phe_name') ? 'error' : ''"
                :help="error('phe_name') || ''"
              >
                <a-input spellcheck="true" placeholder="Název"
                         v-decorator="['phe_name', {rules: [{max: 125, message: 'Název nemůže být delší než 125 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]" />
              </a-form-item>

              <a-form-item
                label="URL adresa"
                :validate-status="error('phe_url') ? 'error' : ''"
                :help="error('phe_url') || ''"
              >
                <a-input @change="handleChangeUrl" placeholder="URL adresa"
                         v-decorator="['phe_url', {rules: [{required: true, message: 'URL musí být vyplněna!'}]}]" />
              </a-form-item>

              <a-form-item
                label="Varianty produktu"
              >
                <a-row>
                  <a-col :offset="2" :span="22">
                    <a-form-item
                      label="Nadpis"
                      :validate-status="error('phe_option_title') ? 'error' : ''"
                      :help="error('phe_option_title') || ''"
                    >
                      <a-input placeholder="Nadpis"
                               v-decorator="['phe_option_title', {rules: [{max: 40, message: 'Nadpis nemůže být delší než 40 znaků!'}, {required: form.getFieldValue('poe_name') !== undefined && form.getFieldValue('poe_name').length > 0, message: 'Nadpis musí být vyplněn!'}]}]" />
                    </a-form-item>
                    <a-form-item
                      label="Varianty"
                      :validate-status="error('poe_name') ? 'error' : ''"
                      :help="error('poe_name') || ''"
                    >
                      <a-select
                        placeholder="Varianty"
                        mode="tags"
                        v-decorator="['poe_name', {rules: [{required: form.getFieldValue('phe_option_title') !== undefined && form.getFieldValue('phe_option_title').length > 0, message: 'Varianta musí být vyplněn!'}]}]"
                        :allowClear="true"
                        :maxTagTextLength="40"
                        :open="false"
                      ></a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form-item>

              <a-form-item
                label="Stručný popisek"
                :validate-status="error('ple_description_short') ? 'error' : ''"
                :help="error('ple_description_short') || ''"
              >
                <a-textarea
                  :autoSize="{ minRows: 4, maxRows: 10 }" placeholder="Stručný popisek"
                  v-decorator="['ple_description_short', {rules: [{max: 200, message: 'Stručný popisek nemůže být delší než 200 znaků!'}]}]" />
              </a-form-item>

              <a-form-item
                label="Popisek"
                :validate-status="error('ple_description_long') ? 'error' : ''"
                :help="error('ple_description_long') || ''"
              >
                <ckeditor @ready="initCKEditor" :editor="editor" :config="editorConfig" class="editor"
                          v-model="editorContent"></ckeditor>
                <a-input class="d-none" v-decorator="['ple_description_long']"></a-input>
              </a-form-item>

              <h4 class="text-black mt-4 mb-3"><strong>Ostatní</strong></h4>

              <a-form-item
                label="Cena bez DPH [Kč]"
                :validate-status="error('phy_price_no_vat') ? 'error' : ''"
                :help="error('phy_price_no_vat') || ''"
              >
                <a-input-number
                  :min="0.00"
                  :step="0.01"
                  :precision="2"
                  placeholder="Cena bez DPH [Kč]"
                  style="width: 100%;"
                  @change="calculateVat"
                  v-decorator="[`phy_price_no_vat`, { initialValue: 0, rules: [{ required: true, message: 'Cena bez DPH [Kč] musí být vyplněn!' }]}]"
                />
              </a-form-item>
              <a-form-item
                label="DPH [%]"
                :validate-status="error('vat') ? 'error' : ''"
                :help="error('vat') || ''"
              >
                <a-select
                  placeholder="-- Vyber DPH --"
                  :options="vats.map(x => { return { value: x, title: x, label: x, key: x } })"
                  v-decorator="['vat', { initialValue: vats[0], rules: [{ required: true, message: 'DPH musí být vyplněné!' }] }]"
                  @change="changeVat"
                ></a-select>
              </a-form-item>
              <a-form-item
                label="Cena s DPH [Kč]"
                :validate-status="error('phy_price_vat') ? 'error' : ''"
                :help="error('phy_price_vat') || ''"
              >
                <a-input-number
                  :min="0.00"
                  :step="0.01"
                  :precision="2"
                  placeholder="Cena s DPH [Kč]"
                  style="width: 100%;"
                  @change="calculateNoVat"
                  v-decorator="[`phy_price_vat`, { initialValue: 0, rules: [{ required: true, message: 'Cena s DPH [Kč] musí být vyplněn!' }]}]"
                />
              </a-form-item>

              <a-form-item
                :validate-status="error('put_availability') ? 'error' : ''"
                :help="error('put_availability') || ''"
              >
                <span slot="label">
                  Dostupnost
                  <a-tooltip title="-1 = není skladem | 0 = na skladě | 1-99 = za kolik dní bude na skladě.">
                    <a-icon type="question-circle-o" />
                  </a-tooltip>
                </span>
                <a-input-number
                  :min="-1"
                  :step="1"
                  :precisison="0"
                  placeholder="Dostupnost"
                  style="width: 100%;"
                  v-decorator="[`put_availability`, { initialValue: 0, rules: [{ required: true, message: 'Dostupnost musí být vyplněn!' }]}]"
                />
              </a-form-item>

              <a-form-item label="Zveřejnit?">
                <a-checkbox
                  v-decorator="['put_is_active', { valuePropName: 'checked', initialValue: true, rules: [{ required: true, message: 'Zveřejnit musí být vyplněn!' }] }]" />
              </a-form-item>

              <a-form-item label="Prodejné?">
                <a-checkbox
                  v-decorator="['sellable', { valuePropName: 'checked', initialValue: true, rules: [{ required: true, message: 'Prodejné musí být vyplněn!' }] }]" />
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="categories">
              <a-badge dot slot="tab">
                <a-icon type="bars" />
                Kategorie
              </a-badge>
              <a-form-item>
                <a-tree
                  :treeData="item.categories.treeData"
                  :checkable="true"
                  :selectedKeys="item.categories.selectedKeys"
                  :checkedKeys="item.categories.checkedKeys"
                  @select="onCategorySelect"
                  @check="onCategoryCheck"
                  :checkStrictly="true"
                >
                </a-tree>
                <a-empty v-if="item.categories.treeData.length === 0" />
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="parameters">
              <a-badge dot slot="tab">
                <ion-icon class="anticon hydrated" name="funnel-outline"></ion-icon>
                Parametry
              </a-badge>
              <a-form-item
                label="Hmotnost [g]"
                :validate-status="error('phy_weight') ? 'error' : ''"
                :help="error('phy_weight') || ''"
                :required="true"
              >
                <a-input-number
                  :min="0"
                  :step="1"
                  :precision="0"
                  placeholder="Hmotnost [g]"
                  style="width: 100%;"
                  v-decorator="[`phy_weight`, { rules: [{ required: true, message: 'Hmotnost [g] musí být vyplněn!' }]}]"
                />
              </a-form-item>
              <a-form-item
                label="Minimální počet kusů"
                :validate-status="error('min_quantity') ? 'error' : ''"
                :help="error('min_quantity') || ''"
                :required="true"
              >
                <a-input-number
                  :min="1"
                  :step="1"
                  :precision="0"
                  placeholder="Minimální počet kusů"
                  style="width: 100%;"
                  v-decorator="[`min_quantity`, { initialValue: 1, rules: [{ required: true, message: 'Minimální počet kusů musí být vyplněn!' }]}]"
                />
              </a-form-item>
              <a-form-item
                label="EAN"
                :validate-status="error('put_ean') ? 'error' : ''"
                :help="error('put_ean') || ''"
              >
                <a-input placeholder="EAN"
                         v-decorator="['put_ean', {rules: [{max: 20, message: 'EAN nemůže být delší než 20 znaků!'}]}]" />
              </a-form-item>
              <a-form-item
                label="Kód"
                :validate-status="error('put_code') ? 'error' : ''"
                :help="error('put_code') || ''"
              >
                <a-input placeholder="Kód"
                         v-decorator="['put_code', {rules: [{max: 100, message: 'Kód nemůže být delší než 100 znaků!'}]}]" />
              </a-form-item>
              <a-form-item
                label="Záruka [měsíc]"
                :validate-status="error('put_warranty') ? 'error' : ''"
                :help="error('put_warranty') || ''"
              >
                <a-input-number
                  :min="0"
                  :step="1"
                  :precision="0"
                  placeholder="Záruka [měsíc]"
                  style="width: 100%;"
                  v-decorator="[`put_warranty`]"
                />
              </a-form-item>
              <a-form-item
                label="Výrobce"
                :validate-status="error('mur_id') ? 'error' : ''"
                :help="error('mur_id') || ''"
              >
                <a-select
                  placeholder="-- Vyber výrobce --"
                  :loading="item.manufacturers.loading"
                  :options="manufacturers.map(x => { return { value: x.mur_id.toString(), label: '#' + x.mur_id + ' | ' + x.mur_name } })"
                  v-decorator="['mur_id']"
                  :allowClear="true"
                ></a-select>
              </a-form-item>
              <a-form-item
                label="Parametry"
                :validate-status="error('parameters') ? 'error' : ''"
                :help="error('parameters') || ''"
              >
                <a-tree-select
                  :allowClear="true"
                  :multiple="true"
                  placeholder="-- Vyber parametry a jejich hodnoty --"
                  :treeData="item.parameters.treeData"
                  :loading="item.parameters.loading"
                  v-decorator="['parameters']"
                ></a-tree-select>
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="groups">
              <a-badge dot slot="tab">
                <a-icon type="percentage" />
                Slevy
              </a-badge>

              <my-group
                :error="error"
                :public-groups="publicGroups"
                :private-groups="privateGroups"
                :private-groups-object="mPrivateGroups"
                :public-groups-object="mPublicGroups"
                :load-private-groups="loadPrivateGroups"
                :show-private-groups="showPrivateGroups"
                :hide-private-groups="hidePrivateGroups"
                :language="language"
                :get-private-group-initial-value="getPrivateGroupInitialValue"
                :discount-name="discountName"
              ></my-group>

            </a-tab-pane>
            <a-tab-pane key="deliveries">
              <span slot="tab">
                <a-icon type="car" />
                Doprava
              </span>
              <a-table
                :rowKey="record => record.dly_id"
                :columns="item.deliveries.columns"
                :loading="item.deliveries.loading"
                :dataSource="deliveries"
                :pagination="deliveries.length > this.$pageSize"
                class="utils__scrollTable"
                :scroll="{ x: '100%' }"
              >
                <router-link
                  slot="dly_id"
                  slot-scope="value"
                  class="utils__link--underlined"
                  :to="'/ecommerce/delivery/detail/' + value"
                >#{{ value }}
                </router-link>
                <span
                  slot="hide"
                  slot-scope="value"
                >
                  <a-form-item
                    label=""
                    class="m-0"
                  >
                    <a-checkbox
                      @change="() => {
                        form.setFieldsValue({
                          ['pdy_fixed_price[' + value.dly_id + ']']: undefined,
                        })
                      }"
                      v-decorator="[`pdy_always_hide[${value.dly_id}]`, { valuePropName: 'checked' } ]" />
                  </a-form-item>
                </span>
                <template slot="customRender" slot-scope="text">
                  <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
                  <span v-else>{{ text }}</span>
                </template>
                <span
                  slot="price"
                  slot-scope="value"
                >
                  <a-form-item
                    class="m-0"
                    label="">
                    <a-input-number
                      :disabled="form.getFieldValue(`pdy_always_hide[${value.dly_id}]`) !== undefined && form.getFieldValue(`pdy_always_hide[${value.dly_id}]`) === true"
                      :min="0.00"
                      :step="0.01"
                      :precision="2"
                      style="width: 100%;"
                      v-decorator="[`pdy_fixed_price[${value.dly_id}]`]"
                    />
                  </a-form-item>
                </span>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="feeds">
              <a-badge slot="tab">
                <ion-icon class="anticon hydrated" name="cloud-outline"></ion-icon>
                Feedy
              </a-badge>

              <a-tabs @change="key => onTabFeedChange(key, 'tabFeedActiveKey')" :activeKey="tabFeedActiveKey">
                <a-tab-pane key="import">
                  <a-badge slot="tab">
                    <ion-icon class="anticon hydrated" name="cloud-download-outline"></ion-icon>
                    Import
                  </a-badge>

                  <a-empty v-if="feeds.length === 0">
                    <a-spin v-if="item.feeds.loading" />
                  </a-empty>

                  <a-row v-else type="flex" justify="start" :gutter="30">
                    <a-col :xl="6" :md="8" :sm="12" :xs="24" v-for="fc in feeds" :key="'fc'+fc.id">
                      <a-card style="border-radius: 4px;">
                        <div class="utils__title">{{ fc.name }}</div>
                        <a-form-item
                          label="Identifikační hodnota ve feedu"
                          :validate-status="error('identifier['+fc.id+']') ? 'error' : ''"
                          :help="error('identifier['+fc.id+']') || ''"
                        >
                          <a-input placeholder="Identifikační hodnota ve feedu"
                                   v-decorator="['identifier['+fc.id+']', {rules: [{max: 100, message: 'Identifikační hodnota ve feedu nemůže být delší než 100 znaků!'}, {required: form.getFieldValue('sync['+fc.id+']') !== undefined && form.getFieldValue('sync['+fc.id+']') , message: 'Identifikační hodnota ve feedu musí být vyplněna!'}]}]" />
                        </a-form-item>
                        <a-form-item
                          label="Synchronizovat"
                        >
                          <a-checkbox
                            v-decorator="['sync['+fc.id+']', { valuePropName: 'checked', initialValue: false }]"></a-checkbox>
                        </a-form-item>
                      </a-card>
                    </a-col>
                  </a-row>
                </a-tab-pane>
                <a-tab-pane key="export">
                  <a-badge slot="tab">
                    <ion-icon class="anticon hydrated" name="cloud-upload-outline"></ion-icon>
                    Export
                  </a-badge>

                  <a-tabs>
                    <a-tab-pane key="google">
                      <a-badge slot="tab">
                        <ion-icon class="anticon hydrated" name="logo-google"></ion-icon>
                        Google
                      </a-badge>

                      <a-tabs>
                        <a-tab-pane key="category">
                          <a-badge slot="tab">
                            <a-icon type="bars" />
                            Kategorie
                          </a-badge>

                          <a-form-item
                            label="Vybrat"
                            class="mb-2"
                          >
                            <a-switch v-model="item.exportFeeds.google.categories.switch"></a-switch>
                          </a-form-item>

                          <my-table
                            v-on:changeSelectedRowKeys="changeSelectedGoogleCategory"
                            v-on:fetch="fetchGoogleCategory"
                            :select-multiple="false"
                            default-order="asc"
                            :loading="loading"
                            :columns="item.exportFeeds.google.categories.columns"
                            :data-source="googleCategories"
                            id-name="id"
                            :table-name="item.exportFeeds.google.categories.tableName"
                          >
                          </my-table>
                        </a-tab-pane>
                      </a-tabs>
                    </a-tab-pane>
                  </a-tabs>
                </a-tab-pane>
              </a-tabs>
            </a-tab-pane>
            <a-tab-pane key="images">
              <span slot="tab">
                <a-icon type="picture" />
                Obrázky
              </span>
              <my-form-image-item
                title="Obrázky"
                subject="Produkt"
                decorator-name="imageSelected"
                :image="thumbImage"
                :image-next-count="thumbImageNextCount"
                :images="thumbImages"
                :on-image-switch-change="onThumbImageSwitchChange"
                :on-next-images-click="onNextThumbImagesClick"
                :form="form"
                type="product"
              >
              </my-form-image-item>

            </a-tab-pane>
            <a-tab-pane key="payments">
              <span slot="tab">
                <a-icon type="credit-card" />
                Platba
              </span>
              <a-table
                :rowKey="record => record.pmt_id"
                :columns="item.payments.columns"
                :loading="item.payments.loading"
                :dataSource="payments"
                :pagination="payments.length > this.$pageSize"
                class="utils__scrollTable"
                :scroll="{ x: '100%' }"
              >
                <router-link
                  slot="pmt_id"
                  slot-scope="value"
                  class="utils__link--underlined"
                  :to="'/ecommerce/payment/detail/' + value"
                >#{{ value }}
                </router-link>
                <span
                  slot="enable"
                  slot-scope="value">
                  <a-form-item
                    label=""
                    class="m-0"
                  >
                    <a-checkbox
                      v-decorator="[`payments[${value.pmt_id}]`, { valuePropName: 'checked', initialValue: true }]"></a-checkbox>
                  </a-form-item>
                </span>
                <template slot="customRender" slot-scope="text">
                  <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
                  <span v-else>{{ text }}</span>
                </template>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="similar">
              <span slot="tab">
                <ion-icon class="anticon hydrated" name="logo-apple" />
                Podobné produkty
              </span>
              <a-row>
                <h4 class="text-black mt-4 mb-3"><strong>Vybrané podobné produkty ({{ item.similar.selected.length
                  }})</strong></h4>
                <a-list :grid="{ gutter: 16, xs: 2, sm: 2, md: 4, lg: 4, xl: 4, xxl: 6 }"
                        :data-source="item.similar.selected">
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a-card class="text-center">
                      <a-alert v-if="item.history_languages === null || item.history_languages.length === 0"
                               type="warning" :message="'#' + item.put_id + ' Název není nastaven'"
                               :showIcon="true"></a-alert>
                      <template v-else>
                        #{{ item.put_id + " " + item.history_languages[0].pivot.phe_name }}
                      </template>
                      <a-checkbox class="mt-2 d-block" v-model="item.selected"
                                  @change="onProductSimilarChange(item)"></a-checkbox>
                    </a-card>
                  </a-list-item>
                </a-list>
              </a-row>
              <a-row>
                <h4 class="text-black mt-4 mb-3"><strong>Seznam produktů</strong></h4>
                <a-col :span="8" :offset="8">
                  <a-tree-select
                    style="display:block;margin-bottom: 1rem"
                    :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                    :treeData="item.similar.categories.treeData"
                    placeholder="-- Vyber kategorii --"
                    @change="this.changeCategory"
                    :value="item.similar.categories.selected"
                    :loading="item.categories.loading"
                  >
                  </a-tree-select>
                </a-col>
                <a-col :span="8" style="text-align:right;">
                  <a-input-search
                    placeholder="Hledat..."
                    style="width: 200px;"
                    @search="handleProductSearch"
                  />
                </a-col>
              </a-row>
              <a-table
                tableLayout="auto"
                :rowSelection="productRowSelection"
                :rowKey="record => record.put_id"
                :columns="item.similar.products.columns"
                :loading="item.similar.products.loading"
                :dataSource="product"
                class="utils__scrollTable"
                :scroll="{ x: '100%' }"
              >
                <router-link
                  slot="put_id"
                  slot-scope="value"
                  class="utils__link--underlined"
                  :to="'/ecommerce/product/detail/' + value"
                >#<span v-if="item.similar.products.searchText">
                    <template
                      v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${item.similar.products.searchText})|(?=${item.similar.products.searchText})`, 'i'))">
                      <mark
                        v-if="fragment.toLowerCase() === item.similar.products.searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                      >{{ fragment }}</mark>
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>{{ value }}</template>
                </router-link>
                <template slot="customRender" slot-scope="text">
                  <a-alert v-if="text === undefined || text === null" message="není nastaveno" type="warning"
                           showIcon />
                  <span v-else-if="item.similar.products.searchText">
                    <template
                      v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${item.similar.products.searchText})|(?=${item.similar.products.searchText})`, 'i'))">
                      <mark
                        v-if="fragment.toLowerCase() === item.similar.products.searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                      >{{ fragment }}</mark>
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>{{ text }}</template>
                </template>
                <span
                  slot="active"
                  slot-scope="value"
                  :class="[value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
                >
                  <span v-if="item.similar.products.searchText">
                    <template
                      v-for="(fragment, i) in (value ? 'ano' : 'ne').toString().split(new RegExp(`(?<=${item.similar.products.searchText})|(?=${item.similar.products.searchText})`, 'i'))">
                      <mark
                        v-if="fragment.toLowerCase() === item.similar.products.searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                      >{{ fragment }}</mark>
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>{{ value ? "ano" : "ne" }}</template>
                </span>
                <template slot="footer">
                  {{ product.length }} položek
                </template>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="seo">
              <span slot="tab">
                <a-icon type="global" />
                SEO
              </span>
              <a-form-item
                label="Meta keywords"
                :validate-status="error('meta_keywords') ? 'error' : ''"
                :help="error('meta_keywords') || ''"
              >
                <a-select placeholder="-- Vyber meta keywords --" :loading="item.meta_keywords.loading" mode="tags"
                          :options="metaKeywords.map(x => { return { value: x.mkd_id.toString(), label: x.languages.length > 0 ? '# ' + x.mkd_id + ' | ' + x.languages[0].pivot.mkd_name : '# ' + x.mkd_id + ' | není nastaveno' } })"
                          v-decorator="['meta_keywords']"></a-select>
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="files">
              <a-row>
                <h4 class="text-black mt-4 mb-3"><strong>Vybrané soubory ({{ item.files.selected.length }})</strong>
                </h4>
                <a-list :grid="{ gutter: 16, xs: 2, sm: 2, md: 4, lg: 4, xl: 4, xxl: 6 }"
                        :data-source="item.files.selected">
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a-card class="text-center">
                      #{{ item.fie_id + " | " + item.fie_name + "." + item.fie_format }}
                      <a-checkbox class="mt-2 d-block" v-model="item.selected"
                                  @change="onFileChange(item)"></a-checkbox>
                    </a-card>
                  </a-list-item>
                </a-list>
              </a-row>
              <span slot="tab">
                <a-icon type="file-pdf" />
                Soubory
              </span>
              <div class="row mb-3">
                <div class="text-right col-md-12">
                  <a-input-search
                    placeholder="Hledat..."
                    style="width: 200px"
                    @search="handleFileSearch"
                  />
                </div>
              </div>
              <a-table
                :rowSelection="fileRowSelection"
                :rowKey="record => record.fie_id"
                :columns="item.files.columns"
                :loading="item.files.loading"
                :dataSource="files"
                class="utils__scrollTable"
                :scroll="{ x: '100%' }"
              >
                <router-link
                  slot="fie_id"
                  slot-scope="value"
                  class="utils__link--underlined"
                  :to="'/file/detail/' + value"
                >#<span v-if="item.files.searchText">
                    <template
                      v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${item.files.searchText})|(?=${item.files.searchText})`, 'i'))">
                      <mark
                        v-if="fragment.toLowerCase() === item.files.searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                      >{{ fragment }}</mark>
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>{{ value }}</template>
                </router-link>
                <template slot="customRender" slot-scope="text">
                  <span v-if="item.files.searchText">
                    <template
                      v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${item.files.searchText})|(?=${item.files.searchText})`, 'i'))">
                      <mark
                        v-if="fragment.toLowerCase() === item.files.searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                      >{{ fragment }}</mark>
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>{{ text }}</template>
                </template>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="stickers">
              <span slot="tab">
                <ion-icon class="anticon hydrated" name="bookmark-outline" />
                Štítky
              </span>
              <a-form-item label="Štítky">
                <a-checkbox-group
                  v-if="stickers && stickers.length !== 0"
                  v-decorator="['stickers']"
                  name="stickers"
                  :options="stickers.map(x => {
                    return {
                      label: x.languages[0].pivot.label, value: x.ser_id,
                    }
                  })"
                />
                <a-empty v-else></a-empty>
              </a-form-item>
            </a-tab-pane>
          </a-tabs>
          <div class="form-actions">
            <a-button html-type="submit" type="primary"
                      :disabled="loading || this.validateGroups() || this.validateOptions() || this.validateCategoryChecked() || hasErrors(form.getFieldsError())">
              Přidat
            </a-button>
          </div>
        </a-form>
      </a-col>
    </div>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import editorMixin from '@/services/editorMixin'
import myGroup from '@/components/MyComponents/Groups/my-group'
import groupMixin from '@/components/MyComponents/Groups/group-mixin'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'
import myFormImageItem from '@/components/MyComponents/Gallery/my-form-image-item'
import thumbImageMixin from '@/components/MyComponents/Gallery/thumb-image-mixin'
import inputCategoryUrlMixin from '@/services/inputCategoryUrlMixin'
import MyTable from '@/components/MyComponents/Table/my-table'

function typeOfNaN(x) {
  return Number.isNaN(x) || isNaN(x)
}

export default {
  components: {
    ActionTools, LanguageTab, myFormImageItem, myGroup, MyTable,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  mixins: [imageSizeMixin, thumbImageMixin, groupMixin, inputCategoryUrlMixin, editorMixin],
  data() {
    return {
      discountName: 'pgt_discount',
      hasErrors,
      loading: false,
      dependencies: ['parameters', 'manufacturers', 'files', 'categories', 'meta_keywords', 'payments', 'deliveries'],
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/ecommerce/product/',
          title: 'Seznam',
        },
      ],
      item: {
        exportFeeds: {
          google: {
            categories: {
              loading: false,
              switch: false,
              selected: [],
              tableName: 'google_feed_categories',
              columns: [
                {
                  title: 'ID',
                  dataIndex: 'id',
                  defaultSortOrder: 'ascend',
                  sorter: true,
                  scopedSlots: {
                    customRender: 'customRender',
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                  },
                },
                {
                  title: 'Google id',
                  dataIndex: 'google_id',
                  sorter: true,
                  scopedSlots: {
                    customRender: 'customRender',
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                  },
                },
                {
                  title: 'Kategorie',
                  dataIndex: 'name',
                  sorter: true,
                  scopedSlots: {
                    customRender: 'customRender',
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                  },
                },
                {
                  title: 'Vytvořeno',
                  dataIndex: 'created_at',
                  pattern: '[ROK-MĚSÍC-DEN] 2021-01-07',
                  sorter: true,
                  scopedSlots: {
                    customRender: 'dates',
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                  },
                },
                {
                  title: 'Poslední změna',
                  dataIndex: 'updated_at',
                  pattern: '[ROK-MĚSÍC-DEN] 2021-01-07',
                  sorter: true,
                  scopedSlots: {
                    customRender: 'dates',
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                  },
                },
              ],
            },
          },
        },
        feeds: {
          loaded: false,
          loading: false,
        },
        languages: [],
        history: {},
        history_languages: [],
        options: [],
        similar: {
          categories: {
            treeData: [],
            selected: undefined,
          },
          selected: [],
          products: {
            selectedRowKeys: [],
            selectedItems: [],
            searchText: '',
            loaded: [],
            loading: false,
            columns: [
              {
                title: 'ID',
                dataIndex: 'put_id',
                defaultSortOrder: 'ascend',
                sorter: (a, b) => a.put_id - b.put_id,
                scopedSlots: {
                  customRender: 'put_id',
                },
              },
              {
                title: 'Kód',
                dataIndex: 'put_code',
                sorter: (a, b) => {
                  return a.put_code + ''.localeCompare(b.put_code + '')
                },
                scopedSlots: {
                  customRender: 'customRender',
                },
              },
              {
                title: 'Název',
                dataIndex: 'history_languages[0].pivot.phe_name',
                sorter: (a, b) => {
                  return a.history_languages[0].pivot.phe_name.localeCompare(b.history_languages[0].pivot.phe_name)
                },
                scopedSlots: {
                  customRender: 'customRender',
                },
              },
              {
                title: 'Zveřejněn',
                dataIndex: 'put_is_active',
                sorter: (a, b) => a.put_is_active - b.put_is_active,
                scopedSlots: {
                  customRender: 'active',
                },
              },
            ],
          },
        },
        categories: {
          loading: false,
          treeData: [],
          checkedKeys: [],
          selectedKeys: [],
        },
        manufacturers: {
          loading: false,
          lang: false,
          loaded: [],
        },
        stickers: {
          loading: false,
          loaded: [],
          dispatch: 'sticker/getList',
        },
        files: {
          loading: false,
          selectedItems: [],
          loaded: [],
          selected: [],
          lang: false,
          searchText: '',
          selectedRowKeys: [],
          columns: [
            {
              title: 'ID',
              dataIndex: 'fie_id',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => a.fie_id - b.fie_id,
              scopedSlots: {
                customRender: 'fie_id',
              },
            },
            {
              title: 'Název',
              dataIndex: 'fie_name',
              sorter: (a, b) => {
                return a.fie_name.localeCompare(b.fie_name)
              },
              scopedSlots: {
                customRender: 'customRender',
              },
            },
            {
              title: 'Formát',
              dataIndex: 'fie_format',
              sorter: (a, b) => {
                return a.fie_format.localeCompare(b.fie_format)
              },
              scopedSlots: {
                customRender: 'customRender',
              },
            },
            {
              title: 'Kategorie',
              dataIndex: 'file_category.fcy_name',
              sorter: (a, b) => {
                return a.file_category.fcy_name.localeCompare(b.file_category.fcy_name)
              },
              scopedSlots: {
                customRender: 'customRender',
              },
            },
          ],
          dispatch: 'file/getList',
        },
        parameters: {
          loading: false,
          lang: true,
          loaded: [],
          treeData: [],
        },
        payments: {
          fields: [{
            itemName: 'payments',
            fieldName: 'payments',
          }],
          dispatch: 'payment/getList',
          loading: false,
          payments: [],
          lang: true,
          loaded: [],
          columns: [
            {
              title: 'ID',
              dataIndex: 'pmt_id',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => a.pmt_id - b.pmt_id,
              scopedSlots: {
                customRender: 'pmt_id',
              },
            },
            {
              title: 'Název',
              dataIndex: 'current_history.languages[0].phe_name',
              sorter: (a, b) => {
                return a.current_history.languages[0].phe_name.localeCompare(b.current_history.languages[0].phe_name)
              },
              scopedSlots: {
                customRender: 'customRender',
              },
            },
            {
              title: 'Zobrazit',
              dataIndex: '',
              scopedSlots: {
                customRender: 'enable',
              },
            },
          ],
        },
        meta_keywords: {
          loaded: [],
          lang: true,
          loading: false,
          active: [],
        },
        deliveries: {
          fields: [{
            itemName: 'deliveries',
            fieldName: 'pdy_always_hide',
          }, {
            itemName: 'deliveries',
            fieldName: 'pdy_fixed_price',
          }],
          pdy_fixed_price: [],
          pdy_always_hide: [],
          dispatch: 'delivery/getList',
          loaded: [],
          lang: true,
          loading: false,
          columns: [
            {
              title: 'ID',
              dataIndex: 'dly_id',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => a.dly_id - b.dly_id,
              scopedSlots: {
                customRender: 'dly_id',
              },
            },
            {
              title: 'Název',
              dataIndex: 'current_history.languages[0].pivot.dhe_name',
              sorter: (a, b) => {
                return a.current_history.languages[0].pivot.dhe_name.localeCompare(b.current_history.languages[0].pivot.dhe_name)
              },
              scopedSlots: {
                customRender: 'customRender',
              },
            },
            {
              title: 'Nikdy nezobrazit',
              dataIndex: '',
              scopedSlots: {
                customRender: 'hide',
              },
            },
            {
              title: 'Fixní cena',
              dataIndex: '',
              scopedSlots: {
                customRender: 'price',
              },
            },
          ],
        },
      },
      tabActiveKey: 'main',
      tabFeedActiveKey: 'import',
      loaded: [],
    }
  },
  computed: {
    fileRowSelection() {
      const { selectedRowKeys } = this.item.files
      return {
        selectedRowKeys,
        onChange: this.onFileSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            let found = false
            if (this.item.files.selectedRowKeys.length !== 0) {
              this.files.some(y => {
                if (this.item.files.selectedRowKeys.includes(y.fie_id)) {
                  found = true
                  return true
                }
              })
            }
            const diff = this.item.files.selected.filter(x => !this.files.includes(x))
            if (!found) {
              this.item.files.selectedRowKeys = [...new Set(this.files.map(x => x.fie_id).concat(diff.map(x => x.fie_id)))]
              this.item.files.selected = [...new Set(this.item.files.selected.concat(this.files.map(x => {
                x.selected = true
                return x
              })))]
            } else {
              this.item.files.selectedRowKeys = diff.map(x => x.fie_id)
              this.item.files.selected = diff
            }
            this.item.files.selectedItems = this.item.files.selected
          },
        }],
        onSelection: this.onSelection,
      }
    },
    googleCategories() {
      return this.$store.getters['googleCategory/allItems']
    },
    productRowSelection() {
      const { selectedRowKeys } = this.item.similar.products
      return {
        selectedRowKeys,
        onChange: this.onProductSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            let found = false
            if (this.item.similar.products.selectedRowKeys.length !== 0) {
              this.product.some(y => {
                if (this.item.similar.products.selectedRowKeys.includes(y.put_id)) {
                  found = true
                  return true
                }
              })
            }
            const diff = this.item.similar.selected.filter(x => !this.product.includes(x))
            if (!found) {
              this.item.similar.products.selectedRowKeys = [...new Set(this.product.map(x => x.put_id).concat(diff.map(x => x.put_id)))]
              this.item.similar.selected = [...new Set(this.item.similar.selected.concat(this.product.map(x => {
                x.selected = true
                return x
              })))]
            } else {
              this.item.similar.products.selectedRowKeys = diff.map(x => x.put_id)
              this.item.similar.selected = diff
            }
            this.item.similar.products.selectedItems = this.item.similar.selected
          },
        }],
        onSelection: this.onSelection,
      }
    },
    feeds: function() {
      return this.$store.getters['feeds/allItems']('')
    },
    vats: function() {
      return this.$store.getters['product/getVats']
    },
    manufacturers: function() {
      return this.$store.getters['manufacturer/filteredItems']('')
    },
    files: function() {
      return this.$store.getters['file/getListFilteredItems'](this.item.files.searchText)
    },
    parameters: function() {
      return this.$store.getters['parameter/currentLanguage']('')
    },
    product: function() {
      return this.$store.getters['product/currentLanguage'](this.item.similar.products.searchText)
    },
    metaKeywords: function() {
      return this.$store.getters['metaKeyword/currentLanguage']('')
    },
    deliveries: function() {
      return this.$store.getters['delivery/currentLanguage']('')
    },
    language: function() {
      return this.$store.getters['language/active']
    },
    payments: function() {
      return this.$store.getters['payment/currentLanguage']('')
    },
    categories: function() {
      return this.$store.getters['category/currentLanguage']
    },
    stickers: function() {
      return this.$store.getters['sticker/currentLanguage']('')
    },
  },
  methods: {
    fetchGoogleCategory(params) {
      this.loading = true
      this.$store.dispatch('googleCategory/getList', this.$store.getters['pagination/getQuery'](params, 'id', 'asc'))
        .then((response) => {
          const pagination = this.$store.getters['pagination/getDefault']
          pagination.current = response.current_page
          pagination.total = response.total
          this.$store.commit('pagination/SET_PAGINATION', { name: this.tableName, item: pagination })
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    changeSelectedGoogleCategory(id) {
      this.item.exportFeeds.google.categories.selected = id
    },
    changeCategory(key) {
      this.item.similar.categories.selected = key
    },
    validateCategoryChecked() {
      return this.item.categories.checkedKeys.length === 0 || (this.item.categories.checkedKeys.checked !== undefined && this.item.categories.checkedKeys.checked.length === 0)
    },
    validateOptions() {
      return (this.form.getFieldValue('phe_option_title') !== undefined && this.form.getFieldValue('phe_option_title').trim().length > 0) && (this.form.getFieldValue('poe_name') === undefined || this.form.getFieldValue('poe_name').length === 0)
    },
    onFileSelectChange(selectedRowKeys, items) {
      this.item.files.selectedRowKeys = selectedRowKeys
      this.item.files.selectedItems = items
    },
    onProductSelectChange(selectedRowKeys, items) {
      this.item.similar.products.selectedRowKeys = selectedRowKeys
      this.item.similar.products.selectedItems = items
    },
    toTreeData(data) {
      return data.map(x => {
        return {
          key: 'cey' + x.cey_id,
          value: x.cey_id,
          title: '#' + x.cey_id + ' | ' + (x.languages.length === 0 ? 'není nastaveno' : x.languages[0].pivot.cle_name),
          children: this.toTreeData(x.all_children),
        }
      })
    },
    toParamTreeData(data) {
      return data.map(x => {
        return {
          key: 'prr_' + x.prr_id,
          value: 'prr_' + x.prr_id,
          title: this.getParamTitle(x),
          selectable: false,
          children: x.values.map(y => {
            return {
              key: x.prr_id + '_' + y.vue_id,
              value: x.prr_id + '_' + y.vue_id,
              title: (x.languages.length === 0 ? '#' + x.prr_id : x.languages[0].pivot.ple_name) + ' | ' + (y.languages.length === 0 ? 'není nastaveno' : y.languages[0].pivot.vle_value),
              isLeaf: true,
            }
          }),
        }
      })
    },
    changeVat(vat) {
      this.form.setFieldsValue({
        phy_price_vat: parseInt(this.form.getFieldValue('phy_price_no_vat')) * ((parseInt(vat) / 100) + 1),
      })
    },
    calculateVat(value) {
      this.form.setFieldsValue({
        phy_price_vat: value * ((parseInt(this.form.getFieldValue('vat')) / 100) + 1),
      })
    },
    calculateNoVat(value) {
      this.form.setFieldsValue({
        phy_price_no_vat: value / ((parseInt(this.form.getFieldValue('vat')) / 100) + 1),
      })
    },
    onTabChange(key, type) {
      this[type] = key
    },
    onTabFeedChange(key, type) {
      this[type] = key
    },
    handleFileSearch(searchText) {
      this.item.files.searchText = searchText
    },
    handleProductSearch(searchText) {
      this.item.similar.products.searchText = searchText
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    initData() {
      this.item.categories.loading = true
      Promise.all([
        this.$store.dispatch('category/getList'),
      ]).catch(() => {
      })
        .finally(() => {
          this.item.categories.loading = false
          this.loaded.push(this.language)
        })
    },
    setThenFields(fields) {
      fields.forEach(field => {
        this.item[field.itemName][field.fieldName].forEach((value) => {
          this.form.getFieldDecorator(field.fieldName + '[' + value.key + ']')
          this.form.setFieldsValue({
            [field.fieldName + '[' + value.key + ']']: value.value,
          })
        })
      })
    },
    loadDependency(items, dependencies, thenFields = []) {
      items.forEach((value) => {
        value.loading = true
      })
      Promise.all(dependencies)
        .then(() => {
          this.setThenFields(thenFields)
        }).catch(() => {
        })
        .finally(() => {
          items.forEach((value) => {
            value.loading = false
            value.loaded.push(this.language)
          })
        })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('product/post', this.getData())
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      const metaKeywords = []
      const deliveries = []
      const categories = []
      const images = []
      const payments = []
      const parameters = []
      const options = []
      if (this.form.getFieldValue('meta_keywords') !== undefined) {
        this.form.getFieldValue('meta_keywords').forEach(x => {
          if (!typeOfNaN(x) && this.metaKeywords.find(y => y.mkd_id === parseInt(x))) {
            metaKeywords.push(parseInt(x))
          }
        })
      }
      if (this.item.categories.selectedKeys.length > 0) {
        categories.push({
          cey_id: Number.parseInt(this.item.categories.selectedKeys[0].slice(3)),
          pce_is_main: true,
        })
      }
      this.item.categories.checkedKeys.checked.filter(x => !this.item.categories.selectedKeys.includes(x)).forEach(value => {
        categories.push({
          cey_id: Number.parseInt(value.slice(3)),
          pce_is_main: false,
        })
      })
      if (this.isThumbImageSelected(true)) {
        this.thumbImage.selected.forEach((value, key) => {
          images.push({
            iae_id: value.iae_id,
            pie_is_main: this.isThumbImageSelected(false) && this.thumbImage.selectedOne.iae_id === value.iae_id,
          })
        })
      }
      this.deliveries.forEach(item => {
        if ((this.form.getFieldValue(`pdy_always_hide[${item.dly_id}]`) !== undefined && this.form.getFieldValue(`pdy_always_hide[${item.dly_id}]`) !== false) || (this.form.getFieldValue(`pdy_fixed_price[${item.dly_id}]`) !== undefined && this.form.getFieldValue(`pdy_fixed_price[${item.dly_id}]`) !== null)) {
          deliveries.push({
            dly_id: item.dly_id,
            pdy_always_hide: this.form.getFieldValue(`pdy_always_hide[${item.dly_id}]`) === true,
            pdy_fixed_price: this.form.getFieldValue(`pdy_fixed_price[${item.dly_id}]`) !== undefined && this.form.getFieldValue(`pdy_fixed_price[${item.dly_id}]`) >= 0 ? this.form.getFieldValue(`pdy_fixed_price[${item.dly_id}]`) : null,
          })
        }
      })
      if (this.form.getFieldValue('payments') !== undefined) {
        this.form.getFieldValue('payments').forEach((value, key) => {
          if (this.form.getFieldValue(`payments[${key}]`) !== null && this.form.getFieldValue(`payments[${key}]`) === false) {
            payments.push(key)
          }
        })
      }
      if (this.form.getFieldValue('parameters') !== undefined) {
        this.form.getFieldValue('parameters').forEach(value => {
          let found = false
          const prrId = parseInt(value.substr(0, value.indexOf('_')))
          const vueId = parseInt(value.substr(value.indexOf('_') + 1))
          parameters.some((x, key) => {
            if (x.prr_id === prrId) {
              found = true
              parameters[key].values.push(vueId)
              return true
            }
          })
          if (!found) {
            parameters.push({
              prr_id: prrId,
              values: [vueId],
            })
          }
        })
      }
      this.item.options.forEach(value => {
        const filteredItems = value.poe_name !== undefined ? value.poe_name.filter(x => x !== undefined && x.trim().length > 0) : []
        if (filteredItems.length > 0) {
          options.push({
            lge_id: value.lge_id,
            items: filteredItems,
          })
        }
      })
      let googleFeedCategoryId = null
      if (this.item.exportFeeds.google.categories.switch &&
        Array.isArray(this.item.exportFeeds.google.categories.selected) &&
        this.item.exportFeeds.google.categories.selected.length !== 0) {
        googleFeedCategoryId = this.item.exportFeeds.google.categories.selected[0]
      }

      let returnObj = {
        mur_id: this.form.getFieldValue('mur_id') === undefined ? null : parseInt(this.form.getFieldValue('mur_id')),
        put_availability: parseInt(this.form.getFieldValue('put_availability')),
        google_feed_category_id: googleFeedCategoryId,
        min_quantity: parseInt(this.form.getFieldValue('min_quantity')),
        put_is_active: this.form.getFieldValue('put_is_active') === true,
        sellable: this.form.getFieldValue('sellable') === true,
        put_ean: this.form.getFieldValue('put_ean') === undefined || this.form.getFieldValue('put_ean').trim().length === 0 ? null : this.form.getFieldValue('put_ean'),
        put_code: this.form.getFieldValue('put_code') === undefined || this.form.getFieldValue('put_code').trim().length === 0 ? null : this.form.getFieldValue('put_code'),
        put_warranty: this.form.getFieldValue('put_warranty') === undefined ? null : this.form.getFieldValue('put_warranty'),
        languages: this.item.languages
          .filter(x => (
            x.ple_description_long !== undefined && x.ple_description_long.trim().length > 0) || (x.ple_description_short !== undefined && x.ple_description_short.trim().length > 0),
          ).map(x => {
            return {
              lge_id: x.lge_id,
              ple_description_long: x.ple_description_long !== undefined && x.ple_description_long.trim().length > 0 ? x.ple_description_long : null,
              ple_description_short: x.ple_description_short !== undefined && x.ple_description_short.trim().length > 0 ? x.ple_description_short : null,
            }
          }),
        options: options,
        history: {
          phy_price_vat: this.form.getFieldValue('phy_price_vat'),
          phy_price_no_vat: this.form.getFieldValue('phy_price_no_vat'),
          phy_weight: this.form.getFieldValue('phy_weight') === undefined ? null : this.form.getFieldValue('phy_weight'),
          vat: parseInt(this.form.getFieldValue('vat')),
        },
        history_languages: this.item.history_languages.filter(x => x.phe_name !== undefined && x.phe_name.trim().length > 0 && x.phe_url !== undefined && x.phe_url.trim().length > 0).map(x => {
          return {
            lge_id: x.lge_id,
            phe_name: x.phe_name,
            phe_url: x.phe_url,
            phe_option_title: x.phe_option_title !== undefined && x.phe_option_title.trim().length > 0 ? x.phe_option_title : null,
          }
        }),
        meta_keywords: {
          new: {
            count: this.item.meta_keywords.active[0].items.length,
            languages: this.item.meta_keywords.active[0].items.length === 0 ? [] : this.item.meta_keywords.active,
          },
          existing: metaKeywords,
        },
        files: this.item.files.selectedRowKeys,
        images: images,
        deliveries: deliveries,
        categories: categories,
        payments: payments,
        parameters: parameters,
        stickers: this.form.getFieldValue('stickers') === undefined || this.form.getFieldValue('stickers') === null ? [] : this.form.getFieldValue('stickers'),
        similar: this.item.similar.selected.map(x => x.put_id),
      }
      const feeds = []
      this.feeds.forEach(x => {
        if (this.form.getFieldValue('identifier[' + x.id + ']') !== undefined && this.form.getFieldValue('identifier[' + x.id + ']').trim().length !== 0) {
          feeds.push({
            feed_id: x.id,
            sync: this.form.getFieldValue('sync[' + x.id + ']') === undefined ? false : this.form.getFieldValue('sync[' + x.id + ']'),
            identifier: this.form.getFieldValue('identifier[' + x.id + ']'),
          })
        }
      })
      if (feeds.length !== 0) {
        returnObj.feeds = feeds
      }
      returnObj = this.setGroups(returnObj, 'group_discounts', this.discountName)
      return returnObj
    },
    onCategoryCheck(checkedKeys) {
      this.item.categories.checkedKeys = checkedKeys
    },
    onCategorySelect(selectedKeys) {
      this.item.categories.selectedKeys = selectedKeys
    },
    changeItems(formItems, itemName, storeItem, id, oldLang) {
      formItems.forEach(formItem => {
        this.$store.getters[storeItem + '/byLanguage']('', oldLang).forEach((value, key) => {
          if (value !== undefined && this.form.getFieldValue(formItem + '[' + value[id] + ']') !== undefined) {
            let found = false
            this.item[itemName][formItem].some(itemItem => {
              if (itemItem.key === value[id]) {
                itemItem.value = this.form.getFieldValue(formItem + '[' + value[id] + ']')
                found = true
                return true
              }
            })
            if (!found) {
              this.item[itemName][formItem].push({
                key: value[id],
                value: this.form.getFieldValue(formItem + '[' + value[id] + ']'),
              })
            }
          }
        })
      })
    },
    handleChange(oldLanguage) {
      const foundObj = {
        history_languages: false,
        meta: false,
        deliveries: false,
        payments: false,
        languages: false,
        options: false,
      }
      const newObj = {
        history_languages: {
          lge_id: oldLanguage,
          phe_name: this.form.getFieldValue('phe_name'),
          phe_url: this.form.getFieldValue('phe_url'),
          phe_option_title: this.form.getFieldValue('phe_option_title'),
        },
        languages: {
          lge_id: oldLanguage,
          ple_description_short: this.form.getFieldValue('ple_description_short'),
          ple_description_long: this.form.getFieldValue('ple_description_long'),
        },
        options: {
          lge_id: oldLanguage,
          poe_name: this.form.getFieldValue('poe_name'),
        },
        meta: {
          lge_id: oldLanguage,
          items: this.form.getFieldValue('meta_keywords') === undefined ? [] : this.form.getFieldValue('meta_keywords').filter(x => {
            return !(!typeOfNaN(x) && this.metaKeywords.find(y => y.mkd_id === parseInt(x)))
          }),
        },
      }
      this.changeItems(['pdy_always_hide', 'pdy_fixed_price'], 'deliveries', 'delivery', 'dly_id', oldLanguage)
      this.changeItems(['payments'], 'payments', 'payment', 'pmt_id', oldLanguage)

      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.languages
          foundObj.languages = true
          return true
        }
      })
      this.item.meta_keywords.active.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.meta_keywords.active[key] = newObj.meta
          foundObj.meta = true
          return true
        }
      })
      this.item.history_languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.history_languages[key] = newObj.history_languages
          foundObj.history_languages = true
          return true
        }
      })
      this.item.options.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.options[key] = newObj.options
          foundObj.options = true
          return true
        }
      })
      if (!foundObj.meta) {
        this.item.meta_keywords.active.push(newObj.meta)
      }
      if (!foundObj.languages) {
        this.item.languages.push(newObj.languages)
      }
      if (!foundObj.history_languages) {
        this.item.history_languages.push(newObj.history_languages)
      }
      if (!foundObj.options) {
        this.item.options.push(newObj.options)
      }
    },
    loadParameters() {
      this.loadDependency([
        this.item.parameters,
        this.item.manufacturers,
      ], [
        this.$store.dispatch('parameter/getList', 'values.languages'),
        this.$store.dispatch('manufacturer/getList'),
      ])
    },
    loadOthers(value, thenFields = []) {
      this.loadDependency([
        this.item[value],
      ], [
        this.$store.dispatch(this.item[value].dispatch),
      ], thenFields)
    },
    loadMetaKeywords() {
      this.loadDependency([
        this.item.meta_keywords,
      ], [
        this.$store.dispatch('metaKeyword/getList'),
      ])
    },
    loadFeeds() {
      this.loading = true
      this.item.feeds.loading = true
      this.$store.dispatch('feeds/getList')
        .then(() => {
          this.item.feeds.loaded = true
        }).catch(() => {
        })
        .finally(() => {
          this.loading = false
          this.item.feeds.loading = false
        })
    },
    loadProducts() {
      if (this.language !== null) {
        this.loadDependency([
          this.item.similar.products,
        ], [
          this.$store.dispatch('product/getList', this.item.similar.categories.selected),
        ])
      }
    },
    onFileChange(item) {
      this.item.files.selected.splice(this.item.files.selected.indexOf(item), 1)
      this.item.files.selectedRowKeys.splice(this.item.files.selectedRowKeys.indexOf(item.fie_id), 1)
    },
    onProductSimilarChange(item) {
      this.item.similar.selected.splice(this.item.similar.selected.indexOf(item), 1)
      this.item.similar.products.selectedRowKeys.splice(this.item.similar.products.selectedRowKeys.indexOf(item.put_id), 1)
    },
    getParamTitle(x) {
      const title = '#' + x.prr_id
      if (!x.languages || x.languages.length === 0) {
        return title + ' | název není nastaven'
      }
      if (x.languages[0].pivot.ple_unit !== null) {
        return title + ' | ' + x.languages[0].pivot.ple_name + ' [' + x.languages[0].pivot.ple_unit + ']'
      }
      return title + ' | ' + x.languages[0].pivot.ple_name
    },
  },
  created() {
    this.inputCategoryUrl.inputUrlName = 'phe_url'
    this.inputCategoryUrl.exclude = undefined
    if (this.categories.length !== 0) {
      this.item.categories.treeData = this.toTreeData(this.categories)
      this.item.similar.categories.treeData = this.toTreeData(this.categories)
      this.form.validateFields()
    }
    if (this.parameters.length !== 0) {
      this.item.parameters.treeData = this.toParamTreeData(this.parameters)
    }
    if (this.language !== null) {
      this.initData()
    }
  },
  watch: {
    editorContent(newValue) {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          ple_description_long: newValue,
        })
      })
    },
    'item.similar.products.selectedItems'(newValue, oldValue) {
      if (this.item.similar.products.selectedRowKeys.length > this.item.similar.selected.length) {
        this.item.similar.products.selectedItems.some(value => {
          let found = false
          this.item.similar.selected.some(sim => {
            if (sim.put_id === value.put_id) {
              found = true
              return true
            }
          })
          if (!found) {
            value.selected = true
            this.item.similar.selected.push(value)
          }
        })
      } else {
        this.item.similar.selected = this.item.similar.selected.filter(x => this.item.similar.products.selectedRowKeys.includes(x.put_id))
      }
    },
    'item.similar.categories.selected'(newValue, oldValue) {
      this.loadProducts()
    },
    'item.files.selectedItems'(newValue, oldValue) {
      if (this.item.files.selectedRowKeys.length > this.item.files.selected.length) {
        this.item.files.selectedItems.some(value => {
          let found = false
          this.item.files.selected.some(sim => {
            if (sim.fie_id === value.fie_id) {
              found = true
              return true
            }
          })
          if (!found) {
            value.selected = true
            this.item.files.selected.push(value)
          }
        })
      } else {
        this.item.files.selected = this.item.files.selected.filter(x => this.item.files.selectedRowKeys.includes(x.fie_id))
      }
    },
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        if (this.tabActiveKey === 'seo' && this.item.meta_keywords.loaded.length !== 0 && !this.item.meta_keywords.loaded.includes(this.language)) {
          this.loadMetaKeywords()
        }
        if (this.tabActiveKey === 'parameters' && this.item.parameters.loaded.length !== 0 && !this.item.parameters.loaded.includes(this.language)) {
          this.loadParameters()
        }
        if (this.tabActiveKey === 'deliveries' || this.tabActiveKey === 'payments') {
          if (this.item[this.tabActiveKey].loaded.length !== 0 && !this.item[this.tabActiveKey].loaded.includes(this.language)) {
            this.loadOthers(this.tabActiveKey, this.item[this.tabActiveKey].fields)
          }
        }
        if (!this.loaded.includes(this.language)) {
          this.initData()
        }
        const foundObj = {
          history_languages: this.item.history_languages.find(x => x.lge_id === newValue),
          languages: this.item.languages.find(x => x.lge_id === newValue),
          options: this.item.options.find(x => x.lge_id === newValue),
          meta: this.item.meta_keywords.active.find(x => x.lge_id === newValue),
        }
        const newObj = {
          phe_name: foundObj.history_languages === undefined ? undefined : foundObj.history_languages.phe_name,
          phe_url: foundObj.history_languages === undefined ? undefined : foundObj.history_languages.phe_url,
          phe_option_title: foundObj.history_languages === undefined ? undefined : foundObj.history_languages.phe_option_title,
          ple_description_short: foundObj.languages === undefined ? undefined : foundObj.languages.ple_description_short,
          ple_description_long: foundObj.languages === undefined ? undefined : foundObj.languages.ple_description_long,
          poe_name: foundObj.options === undefined ? undefined : foundObj.options.poe_name,
        }
        if (this.editorContent !== (newObj.ple_description_long === undefined ? '' : newObj.ple_description_long)) {
          this.editorContent = newObj.ple_description_long === undefined ? '' : newObj.ple_description_long
        }
        newObj.meta_keywords = this.form.getFieldValue('meta_keywords') === undefined ? [] : this.form.getFieldValue('meta_keywords').filter(x => {
          return !typeOfNaN(x) && this.metaKeywords.find(y => y.mkd_id === parseInt(x))
        })
        if (foundObj.meta !== undefined) {
          newObj.meta_keywords = newObj.meta_keywords.concat(foundObj.meta.items)
        }
        newObj.meta_keywords = newObj.meta_keywords.filter((item, index) => newObj.meta_keywords.indexOf(item) === index)
        this.form.setFieldsValue(newObj)
        this.form.validateFields()
      }
    },
    categories(newValue, oldValue) {
      if (newValue !== null && newValue.length > 0) {
        this.item.categories.treeData = this.toTreeData(this.categories)
        this.item.similar.categories.treeData = this.toTreeData(this.categories)
        this.form.validateFields()
      }
    },
    parameters(newValue, oldValue) {
      if (newValue !== null && newValue.length > 0) {
        this.item.parameters.treeData = this.toParamTreeData(this.parameters)
      }
    },
    tabActiveKey(newValue, oldValue) {
      if (['main', 'categories', 'similar', 'images'].includes(newValue)) {
        return false
      }
      if (newValue === 'feeds') {
        if (!this.item.feeds.loaded) {
          this.loadFeeds()
        }
      } else if (newValue === 'seo') {
        if (!this.item.meta_keywords.loaded.includes(this.language)) {
          this.loadMetaKeywords()
        }
      } else if (newValue === 'groups') {
        if (!this.mPublicGroups.loaded.includes(this.language)) {
          this.loadPublicGroups(this.discountName, false)
        }
      } else if (newValue === 'parameters') {
        if (!this.item.parameters.loaded.includes(this.language)) {
          this.loadParameters()
        }
      } else if (this.item[newValue].loaded.length === 0 || (!this.item[newValue].loaded.includes(this.language) && this.item[newValue].lang)) {
        if (newValue === 'deliveries' || newValue === 'payments') {
          this.loadOthers(newValue, this.item[newValue].fields)
        } else {
          this.loadOthers(newValue)
        }
      }
      if (newValue === 'deliveries' || newValue === 'payments') {
        this.setThenFields(this.item[newValue].fields)
      }
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
